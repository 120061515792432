<template>
  <div class="flex flex-col gap-4.5 lg:gap-6">
    <p class="text-tekstovyy text-6 lg:text-5.5 font-700">{{ title }}</p>
    <div class="relative lg:px-3.5 container-swiper-product">
      <Swiper
        class="w-full max-lg:[&>div]:grid max-lg:[&>div]:grid-cols-3 !max-lg:[&>div]:transform-none max-lg:[&>div]:gap-x-2.5 max-lg:[&>div]:gap-y-2 max-md:[&>div]:grid-cols-2 overflow-visible"
        v-bind="(options as any)"
      >
        <SwiperSlide
          v-for="product in children"
          :key="product.id"
          class="h-auto"
        >
          <RestaurantProductsCard
            class="h-full"
            :product="product"
            disble-popap-open
          />
        </SwiperSlide>
      </Swiper>
      <SwiperNavigation
        orientation="left"
        class="absolute -translate-y-1/2 top-1/2 -translate-x-1/2 left-0 max-lg:hidden"
      />
      <SwiperNavigation
        orientation="right"
        class="absolute -translate-y-1/2 top-1/2 translate-x-1/2 right-0 max-lg:hidden"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import type { SwiperOptions } from 'swiper/types';
import type { ProductType } from '~/composables/product/types/UseProductType';

defineProps({
  title: String,
  children: { type: Array as PropType<ProductType[]>, required: true },
  disblePopapOpen: { type: Boolean, default: false }
});

const options: SwiperOptions = {
  modules: [Navigation],
  breakpoints: {
    1024: {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        enabled: true
      },
      spaceBetween: 6,
      slidesPerView: 4,
      enabled: true
    }
  },
  slidesPerView: 'auto',
  navigation: {
    nextEl: '.container-swiper-product .swiper-button-next',
    prevEl: '.container-swiper-product .swiper-button-prev',
    enabled: false
  },
  enabled: false
};
</script>
