<template>
  <Modal
    :ui="{ close: { addition: 'max-lg:sticky max-lg:-mb-9 max-lg:z-10 max-lg:top-2.5 max-lg:ml-auto max-lg:translate-y-0 max-lg:translate-x-0' }, root: { rounded: 'rounded-t-4 lg:rounded-8', padding: 'p-6 max-lg:pb-30', size: 'max-w-230.5', color: 'bg-#F7F9F2 lg:ring-1 ring-#D2DDE3/30 ring-inset lg:shadow-[0_4px_14px_0] shadow-black/6', addition: 'max-lg:p-3 max-lg:rounded-b-0' }, wrapper: { addition: 'max-lg:justify-center max-lg:items-end max-lg:p-0 max-lg:pt-30vh' } }"
    @close="emits('closeModal')"
  >
    
<FormKitLazyProvider config-file="true" :default-config="false">
<div
      v-if="currentFullProductState.currentProduct"
      class="flex flex-col lg:flex-row items-center gap-4 lg:gap-7"
    >
      <RestaurantImageSlider :imgs="[currentFullProductState.currentProduct?.img_path]" />
      <div class="flex-1 max-lg:w-full">
        <p class="text-5.5 text-tekstovyy font-700 leading-1.2 -tracking-0.01em max-lg:hidden">{{ currentFullProductState.currentProduct?.title }}</p>
        <p class="mt-1 text-3.75 text-seryy leading-1 -tracking-0.01em font-500">{{ currentFullProductState.currentProduct?.restaurant.title }}</p>
        <p class="mt-2 text-3.75 text-#455862 leading-1.3 -tracking-0.01em">{{ currentFullProductState.currentProduct?.additional_param?.description }}</p>
        <p class="mt-1 text-4 text-seryy leading-1.3 -tracking-0.01em max-lg:hidden"> {{ currentFullProductState.currentProduct.size_title }} </p>
        <div class="mt-3 bg-#ebf0f2 h-px"></div>
        <p class="mt-3 text-3.75 text-tekstovyy font-700 leading-1 -tracking-0.01em">Состав и калорийность</p>
        <p class="mt-1 text-3.25 text-seryy leading-1.3 -tracking-0.01em">{{ currentFullProductState.currentProduct.subtitle }}</p>
        <div class="mt-2 flex gap-4 max-w-80 justify-between empty:hidden">
          <div
            v-if="currentFullProductState.currentProduct?.additional_param?.calory"
            class="flex flex-col gap-1"
          >
            <p class="text-4 text-tekstovyy font-500 leading-1 -tracking-0.01em">{{ currentFullProductState.currentProduct?.additional_param?.calory }}</p>
            <p class="text-3.25 text-tekstovyy leading-1 -tracking-0.01em">Ккал</p>
          </div>
          <div
            v-if="currentFullProductState.currentProduct?.additional_param?.protein"
            class="flex flex-col gap-1"
          >
            <p class="text-4 text-tekstovyy font-500 leading-1 -tracking-0.01em">{{ currentFullProductState.currentProduct?.additional_param?.protein }}</p>
            <p class="text-3.25 text-tekstovyy leading-1 -tracking-0.01em">Белки</p>
          </div>
          <div
            v-if="currentFullProductState.currentProduct?.additional_param?.fat"
            class="flex flex-col gap-1"
          >
            <p class="text-4 text-tekstovyy font-500 leading-1 -tracking-0.01em">{{ currentFullProductState.currentProduct?.additional_param?.fat }}</p>
            <p class="text-3.25 text-tekstovyy leading-1 -tracking-0.01em">Жиры</p>
          </div>
          <div
            v-if="currentFullProductState.currentProduct?.additional_param?.carbs"
            class="flex flex-col gap-1"
          >
            <p class="text-4 text-tekstovyy font-500 leading-1 -tracking-0.01em">{{ currentFullProductState.currentProduct?.additional_param?.carbs }}</p>
            <p class="text-3.25 text-tekstovyy leading-1 -tracking-0.01em">Углеводы</p>
          </div>
        </div>
        <FormKit
          type="list"
          #="{ value, state }"
          :config="{
            decoratorClass: '$remove:mr-2 ml-2 text-4.75 shadow-[inset_0_2px_3px_0] shadow-red/7',
            wrapperClass: 'w-full justify-between',
            innerClass: 'order-last',
            outerClass: 'border-t border-border/50 py-4',
            optionsClass: 'mt-2',
            labelClass: '$reset text-#455862 text-3.25 leading-1.3 -tracking-0.01em',
            legendClass: 'text-tekstovyy text-3.75 font-600 leading-1 -tracking-0.01em'
          }"
        >
          <!-- <Button :disabled="!state.valid" @click="" class="mt-6">729 ₽</Button> -->
          <div class="flex gap-3 flex-col lg:mt-4 max-lg:fixed max-lg:left-0 max-lg:bottom-0 max-lg:rounded-t-4 max-lg:bg-white max-lg:shadow-[0_0_12px_0] max-lg:shadow-black/8 max-lg:p-3 max-lg:w-[calc(100%-1rem)] max-lg:z-2">
            <p class="flex gap-2 items-end text-tekstovyy text-4.25 leading-1.2 font-500 -tracking-0.01em lg:hidden">
              {{ currentFullProductState.currentProduct?.title }}
              <span class="text-seryy text-3.5">{{ currentFullProductState.currentProduct.size_title }} </span>
            </p>
            <div
              v-if="currentProductInCart?.additional_ingredients.length"
              class="flex gap-2 lg:gap-3.5"
            >
              <p class="inlile items-end text-tekstovyy text-4.25 leading-1.2 font-500 -tracking-0.01em">
                Дополнительные ингрeдиенты:
                <template v-for=" item in currentProductInCart.additional_ingredients">
                  <span class="text-seryy text-3.5">{{ `${item.product_additional_ingredient.title} ` }}</span>
                </template>
              </p>
            </div>
            <div class="flex gap-2 lg:gap-3.5">
              <AddToCartBtn
                v-if="canBeSold"
                :key="countProduct"
                class="[&>div]:!mt-0 w-full"
                :selected-options="(value?.filter(a => a)?.flat() as ProductAdditionalIngredientsGroupsType[])"
                :product="currentFullProductState.currentProduct"
                :count="countProduct"
                :show-price="true"
                :current-price="currentProductInCart?.sum && currentProductInCart?.count ? currentProductInCart?.sum/currentProductInCart?.count : undefined"
              />
              <WidgetsAddToCartBtnCartSmall
                v-if="currentProductInCart && canBeSold"
                :product="currentProductInCart"
              />
            </div>
          </div>
          <div class="mt-4">
            <FormKitSchema
              v-if="schema && !currentProductInCart"
              :schema
            />
          </div>
        </FormKit>
      </div>
    </div>
    <div class="w-[calc(100%+1.5rem)] lg:w-[calc(100%+3rem)] -ml-3 lg:-ml-6 overflow-hidden px-3 lg:px-6 mt-10 lg:mt-12 pb-4 -mb-4">
      <!-- @vue-ignore - исправить типы -->
      <RestaurantProductsSlider
        v-if="currentFullProductState.currentProduct?.additional_products.length"
        title="Добавьте к заказу"
        class=""
        :children="/* @ts-ignore - исправить типы  */
          currentFullProductState.currentProduct?.additional_products"
        disble-popap-open
      />
    </div>
    <Reviews
      v-if="currentFullProductState.currentProduct"
      title="Отзывы покупателей о блюде"
      class="mt-14"
      :product-id="currentFullProductState.currentProduct?.id"
      :can-create-feedback="currentFullProductState.currentProduct?.can_create_feedback"
      review-form-wrapper-class="max-lg:w-[calc(100%+1.5rem)] max-lg:-ml-3"
    >
      <template #title>
        <p class="text-tekstovyy text-6 lg:text-5.5 font-700">Отзывы покупателей о блюде</p>
      </template>
    </Reviews>
</FormKitLazyProvider>

  </Modal>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { FormKitSchema } from '@formkit/vue';
import AddToCartBtn from '~/components/widgets/add-to-cart/AddToCartBtn.vue';
import { useProductPopup } from '~/composables/popups/useProductPopup';
import { cartStore as store } from '#imports';
import type { ProductAdditionalIngredientsGroupsType } from '~/composables/product/types/UseProductType';
import type { CartProductItemType } from '~/composables/cart/types/CartType';
const emits = defineEmits(['closeModal']);

const cartStore = store();
const { cartList } = storeToRefs(cartStore);

const { currentFullProductState } = useProductPopup();
const canBeSold = computed(() => currentFullProductState.value.currentProduct?.is_active && currentFullProductState.value.currentProduct?.is_currently_selling);
const countProduct = ref(0);
// const isProductInCart = ref(false);
const currentProductInCart = ref<CartProductItemType | null>(null);
watch(
  () => cartList.value.map((item) => item.cart_products), // создаем массив всех продуктов во всех cartItems
  (newProductsArrays) => {
    // nextTick();
    // обрабатываем каждый массив продуктов
    nextTick(() => {
      for (const products of newProductsArrays) {
        const product = products.find((product) => product.product_id === currentFullProductState.value.currentProduct?.id);
        if (product) {
          currentProductInCart.value = product;
          countProduct.value = product.count;
          return; // прерываем цикл, так как продукт найден
        }
      }
      countProduct.value = 0; // если продукт не найден во всех cartItems
      currentProductInCart.value = null;
      console.log('0');
    });
  },
  {
    deep: true, // глубокое отслеживание изменений в массивах
    immediate: true
  }
);

// разобраться как это работает, заполнить комментариями, ОБЯЗАТЕЛЬНО!!!!!!!!!!!!!!!!!!!!!!!!!!
const schema = computed(() => {
  if (currentFullProductState.value.currentProduct?.additional_ingredient_groups?.length === 0) { return; }

  return currentFullProductState.value.currentProduct?.additional_ingredient_groups?.map((a) => {
    const options = a.list.map<{ label: string, value: ProductAdditionalIngredientsGroupsType }>((b) => ({ label: b.title, value: { group: a.group, list: [b] }, price: b.price }));
    return {
      $formkit: a.group.group_type.id === 1 ? 'radio' : 'checkbox',
      label: a.group.title,
      validation: a.group.required ? 'required' : '',
      options,
      sectionsSchema: {
        label: {
          children: [
            '$option.label',
            {
              $el: 'span',
              children: [
                ' +',
                '$option.price',
                '₽'
              ],
              attrs: {
                class: 'text-seryy'
              }
            }
          ]
        },
        messages: null
      },
      decoratorIcon: 'check',
      value: a.group.required === 1 ? options?.find(() => true)?.value : null
    };
  });
});
</script>
